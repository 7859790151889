/*
PC header
*/

.site-header {
	background: $site-dark;
}
.header {
	&_inner {
		max-width: 1000px;
		margin: auto;
		display: flex;
		justify-content: space-between;
	}
	&_logo {
		padding: 15px 0;
		text-align: center;
	}
	&_right {
		display: flex;
		.line {
			a {
				height: 100%;
				padding-top: 28px;
			}
		}
		.tel {
			padding-top: 20px;
		}
	}

}