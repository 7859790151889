/*
SP 共通
*/

.pc_disp {
	display: none !important;
}
.sp_br {
	display: block;
}

html {
	font-size: $fzSP;
}

.tel {
	margin-bottom: 10px;
	font-weight: $fwB;
	font-size: $fzSP + 4;
	.num {
		letter-spacing: 1px;
	}
	a {
		font-weight: $fwB;
		color: $white;
		display: inline-block;
		&:before {
			content: "";
			background: url(./img/icon-tel.svg) 0 0 / 100% no-repeat;
			width: 32px;
			height: 32px;
			display: inline-block;
			vertical-align: middle;
			margin: 0 10px 6px 0;
		}
	}
}
.line {
	a {
		display: block;
		background: $line-green;
		color: $white;
		border-radius: 6px;
		padding: 10px 15px 13px;
		font-weight: $fwB;
		color: $white;
	}
}

img.img_logo {
	max-width: 200px;
}