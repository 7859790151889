/*
SP header
*/

.site-header {
	background: $site-dark;
}
.header {
	&_inner {
		padding: 15px 0;
	}
	&_logo {
		margin-bottom: 15px;
		text-align: center;
	}
	&_right {
		text-align: center;
		.line {
			display: inline-block;
		}
	}

}