@import "../_settings.scss";

/*---------------------------------------------

Default CSS (for HTML5)

for all site
coded by cloud-box at 2016-07-07
imported to "page-type.css"

---------------------------------------------*/
/* Reset */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, hr, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{ margin:0; padding:0; border:0; font-size:100%; font:inherit; vertical-align:baseline; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{ display:block; }
html{ font-size:22px; font-family:$gothic; color:$textColor; background-color:$white; overflow-y:scroll; }
body{ line-height:1; }
a, ins{ text-decoration:none; }
h1, h2, h3, h4, h5, h6{ font-weight:$fwB; line-height:1.4; }
address, em, cite, dfn, var{ font-style:normal; }
fieldset{ border:none; }
img{ border:none; width:100%; max-width:100%; height:auto; vertical-align:bottom; }
mark{ background:none; }
p{ line-height:1.5; }
a{ text-decoration:none; backface-visibility:hidden; transform:translateZ(0);
    &:hover{ text-decoration:none; } }
blockquote, q{ quotes:none;
    &:before, &:after{ content:""; content:none; } }
ul, ol{ list-style-position:outside; list-style-type:none; }
table{ border-collapse:collapse; border-spacing:0; }

/*IE用セレクトボックスカスタマイズ*/
select::-ms-expand{ display:none; }

/* Box sizingの定義 */
*, *::before, *::after{ box-sizing:border-box; }
