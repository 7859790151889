/*
PC
*/
@import "_settings.scss";

@media screen and (min-width:769px){ @import "pc/_pc_common.scss";
    @import "pc/_pc_footer.scss";
    @import "pc/_pc_header.scss";
    @import "pc/_pc_top.scss"; }




