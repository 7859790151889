@import "../_settings.scss";
/*---------------------------------------------
BASE Page CSS
---------------------------------------------*/

/* For modern browsers */
.cf:before, .cf:after{ content:""; display:table; }

.cf:after{ clear:both; }

/* For IE 6/7 (trigger hasLayout) */
.cf{ zoom:1; }

#wrapper{ overflow:hidden; }
