/*
PC 共通
*/

.sp_disp {
    display: none !important;
}

p {
	line-height: 1.6;
}

html {
	font-size: $fzPC;
}

.tel {
	padding: 14px 0 10px;
	margin-right: 24px;
	font-weight: $fwB;
	font-size: $fzPC + 4;
	.num {
		letter-spacing: 1px;
		color: $white;
		&:before {
			content: "";
			background: url(./img/icon-tel.svg) 0 0 / 100% no-repeat;
			width: 40px;
			height: 40px;
			display: inline-block;
			vertical-align: middle;
			margin: 0 10px 8px 0;
		}
	}
}
.line {
	a {
		display: block;
		background: $line-green;
		color: $white;
		font-weight: $fwB;
		padding: 24px 24px 23px;
		&:hover {
			opacity: $opacity;
		}
	}
}

img.img_logo {
	max-width: 244px;
}