/*
SP top
*/



.sec {
	.inner {
		padding: 0 15px;
	}
	&_ttl {
		background: $site-dark url(./img/ttl_bk.png) center / 2000px repeat;
		color: $white;
		font-size: $fzSP + 8;
		text-align: center;
		padding: 29px 10px;
		line-height: 38px;
		font-family: "Hiragino Sans";
	}
	&_link {
		li {
			&:nth-child(1),
			&:nth-child(3) {
				background-color: $colorBg;
			}
			a {
				display: block;
				padding: 20px 15px;
			}
		}
		.sec_ttl {
			h2 {
				border-bottom: 3px solid $site-red;
				padding-bottom: 6px;
			}
			span {
				font-size: $fzSP + 18;
				display: block;
				margin-top: 10px;
			}
			img {
				width: 70%;
				margin: 0 3px;
			}
		}
	}
	&_inquiry {
		text-align: center;
		padding: 50px 0;
		strong {
			font-weight: $fwB;
			font-size: 9.6vw;
			line-height: 1.2;
		}
		li {
			margin-top: 25px;
		}
		.tel {
			background-color: $site-dark;
			border-radius: 6px;
			a {
				display: block;
				padding: 6px 6px 2px;
			}
			.icon {
				width: 30px;
			}
		}
		.line {
			a {
				font-size: $fzSP + 3;
				padding: 11px 15px 13px;
			}
		}
		.note {
			font-size: 4.7vw;
		}
	}
	&_fast,
	&_expensive,
	&_results {
		.sec_ttl {
			h2 {
				display: inline-block;
				border-bottom: 3px solid $site-red;
				margin-top: 15px;
				padding-bottom: 6px;
				font-size: 7vw;
				span {
					font-size: 5.6vw;
				}
			}
			.icons {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 50px;
					margin-right: 20px;
				}
				span {
					font-size: $fzSP + 28;
					font-weight: $fwB;
				}
			}
		}		
	}
	&_fast,
	&_expensive {
		.content_box {
			> div {
				padding: 48px 0;
				&:nth-child(1),
				&:nth-child(3) {
					background-color: $colorBg;
				}
			}
			.imgBox {
				margin-bottom: 20px;
			}
			.txtBox {
				strong {
					font-weight: $fwB;
				}
			}
			h3 {
				font-size: 7vw;
				margin-bottom: 15px;
				line-height: 1.2;
			}
			li {
				font-weight: $fwB;
				line-height: 1.5;
			}
		}
	}
	&_results {
		background-color: $colorBg;
		padding-bottom: 68px;
		.lead {
			margin: 24px 0 47px;
		}
		li {
			background-color: $white;
			margin-bottom: 24px;
			font-size: $fzSP - 2;
			.imgBox {
				padding-top: 62.5%;
				position: relative;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.txtBox {
				padding: 22px;
			}
			h3 {
				font-size: $fzSP + 4;
				margin-bottom: 15px;
			}
			.guide {
				font-weight: $fwB;
				margin-bottom: 8px;
			}
		}
	}
	&_voice {
		background-color: $colorBg;
		padding-bottom: 48px;
		.sec_ttl {
			margin-bottom: 48px;
			font-weight: $fwB;
			.ttl_intro {
				display: inline-block;
				border-bottom: 3px solid $site-red;
				margin-bottom: 24px;
				padding: 0 5px 5px;
				font-size: $fzSP + 4;
			}
			span {
				font-size: $fzSP + 10;
				margin: 0 3px;
			}
			img {
				display: block;
				margin: 5px auto;
			}
			h2 {
				font-size: $fzSP + 22;
				line-height: 1;
			}
		}
		li {
			background-color: $white;
			padding: 22px;
			margin-bottom: 24px;
			font-size: $fzSP - 2px;
		}
		.imgBox {
			margin-bottom: 28px;
		}
		.car {
			font-size: $fzSP + 4;
			margin-bottom: 6px;			
		}
		h3 {
			font-size: $fzSP + 4;
			margin-bottom: 14px;
			line-height: 1.2;
		}
		.date {
			font-weight: $fwB;
			margin-bottom: 8px;
			span {
				margin-right: 10px;
			}
		}
	}
	&_flow {
		background-color: $colorBg;
		padding: 32px 0 48px;
		text-align: center;
		h2 {
			font-size: 10vw;
			border-bottom: 3px solid $site-red;
			padding-bottom: 10px;
			margin-bottom: 40px;
		}
		li {
			margin-bottom: 70px;
			position: relative;
			font-size: $fzSP - 2;
			&:after {
				content: "";
				border-top: 22px solid #3A3A3A;
				border-right: 25px solid transparent;
				border-bottom: 22px solid transparent;
				border-left: 25px solid transparent;
				position: absolute;
				left: 50%;
				bottom: -70px;
				margin-left: -25px;
			}
			&:last-child {
				&:after {
					content: none;
				}
			}
		}
		.icon {
			width: 80px;
			margin: 0 auto 22px;
		}
		strong {
			display: inline-block;
			font-weight: $fwB;
			font-size: $fzSP + 10;
			line-height: 1.2;
			border-bottom: 3px solid $site-red;
			padding-bottom: 14px;
		}
	}
}