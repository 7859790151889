/* 設定 */

$serif: "Noto Serif JP", "Yu Mincho",YuMincho, serif;
$gothic: 'Noto Sans JP', Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, "メイリオ", Meiryo, sans-serif;

$white: #fff;
$black: #000;
$textColor: #222;
$line-green: #00B900;
$site-red: #D33022;
$colorBg: #EFEFEF;
$site-dark: #3A3A3A;

$fwB: 700;
$fwN: 400;

$fzSP: 18px;
$fzPC: 22px;
$opacity: .8;
$trsA: all .2s;

/* padding */
@mixin sidePadding {
	padding-left: 20px;
	padding-right: 20px;
}

