/*
PC footer
*/

.site-footer {
	background-color: $site-dark;
	color: $white;
	padding: 32px 0;
}
.footer {
	&_inner {
		max-width: 1000px;
		margin: auto;
		display: flex;
		justify-content: space-between;
	}
	&_logo {
		margin-bottom: 20px;
		width: 244px;
	}
	&_right {
		display: flex;
		justify-content: space-between;
		.tel {
			flex-wrap: wrap;
			font-size: $fzPC + 4;
			padding: 0;
			.note {
				font-weight: $fwN;
				flex-shrink: 0;
				width: 100%;
				font-size: $fzPC - 2;
				margin-top: 12px;
			}
		}
		.line {
			text-align: center;
			border-radius: 0;
			a {
				padding: 29px 24px 29px;
			}
		}
	}
}