/*
SP footer
*/

.site-footer {
	background-color: $site-dark;
	color: $white;
	padding: 32px 0;
}
.footer {
	&_inner {
		padding: 0 15px;
	}
	&_logo {
		margin-bottom: 20px;
		text-align: center;
	}
	&_right {
		.tel {
			flex-wrap: wrap;
			font-size: $fzSP + 4;
			margin-bottom: 20px;
			.note {
				font-weight: $fwN;
				flex-shrink: 0;
				width: 100%;
				font-size: 4.7vw;
				margin-top: 15px;
			}
		}
		.line {
			a {
				text-align: center;
				border-radius: 0;
				padding: 20px;
			}
		}
	}
}