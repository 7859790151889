/*
PC TOP
*/

.mainvisual {
	background-color: #111;
	.imgBox {
		width: 100%;
		height: 100%;
		max-width: 1200px;
		margin: auto;
	}
}
.sec {
	.inner {
		max-width: 1000px;
		margin: auto;
	}
	&_ttl {
		background: $site-dark url(./img/ttl_bk.png) center / 2000px repeat;
		color: $white;
		font-size: $fzPC + 8;
		text-align: center;
		padding: 29px 10px;
		font-family: "Hiragino Sans";
	}
	&_link {
		li {
			&:nth-child(1),
			&:nth-child(3) {
				background-color: $colorBg;
			}
			a {
				display: block;
				padding: 20px 15px;
				max-width: 750px;
				margin: auto;
				&:hover {
					opacity: $opacity;
				}
			}
		}
		.sec_ttl {
			letter-spacing: 1px;
			h2 {
				display: inline-block;
				font-size: $fzPC + 4;
				border-bottom: 3px solid $site-red;
				padding-bottom: 6px;
			}
			span {
				font-size: $fzPC + 28;
				margin: 0 3px;
			}
			img {
				width: 70%;
				margin: 0 3px 8px;
			}
		}
	}
	&_inquiry {
		text-align: center;
		padding: 48px 0;
		strong {
			display: block;
			font-weight: $fwB;
			font-size: $fzPC + 18;
			margin-bottom: 47px;
		}
		ul {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
		li {
			width: calc(50% - 50px);
			max-width: 450px;
			&:nth-child(1) {
				margin-right: 100px;
			}
		}
		.tel {
			background-color: $site-dark;
			border-radius: 10px;
			padding: 18px 17px 13px;
			margin: 0 0 15px;
			font-size: $fzPC + 10;
		}
		.line {
			a {
				border-radius: 10px;
				padding: 23px 17px 24px;
				font-size: $fzPC + 10;
			}
		}
	}
	&_fast,
	&_expensive,
	&_results {
		.sec_ttl {
			.ttl_inner {
				max-width: 792px;
				margin: auto;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
			h2 {
				display: inline-block;
				margin-bottom: 6px;
				padding-bottom: 6px;
				border-bottom: 3px solid $site-red;
				font-size: $fzPC + 10;
				span {
					font-size: $fzPC + 4;
				}
			}
			.icons {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					margin-right: 20px;
				}
			}
			span {
				font-size: $fzPC + 42;
				font-weight: $fwB;
			}
		}		
	}
	&_fast {
		.sec_ttl {
			.icons {
				img {
					width: 80px;
				}
			}
		}
	}
	&_expensive {
		.sec_ttl {
			.icons {
				img {
					width: 92px;
				}
			}
		}
	}
	&_results {
		.sec_ttl {
			.icons {
				img {
					width: 110px;
				}
			}
		}
	}
	&_fast,
	&_expensive {
		.content_box {
			> div {
				padding: 48px 0;
				&:nth-child(1),
				&:nth-child(3) {
					background-color: $colorBg;
				}
				&:nth-child(2) {
					.inner {
						flex-direction: row-reverse;
					}
					.imgBox {
						margin: 0 0 0 50px;
					}
				}
			}
			.inner {
				display: flex;
			}
			.imgBox {
				max-width: 450px;
				margin-right: 50px;
			}
			.txtBox {
				max-width: 500px;
				flex-shrink: 0;
				strong {
					font-weight: $fwB;
				}
			}
			h3 {
				font-size: $fzPC + 18;
				margin-bottom: 39px;
				line-height: 1.2;
			}
			li {
				font-weight: $fwB;
				line-height: 1.5;
			}
		}
	}
	&_results {
		background-color: $colorBg;
		padding-bottom: 68px;
		.lead {
			margin: 24px 0 47px;
			text-align: center;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		li {
			width: calc(50% - 20px);
			max-width: 480px;
			background-color: $white;
			margin-bottom: 24px;
			font-size: $fzPC - 2;
			&:nth-child(2n+1) {
				margin-right: 40px;
			}
			.imgBox {
				padding-top: 62.5%;
				position: relative;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.txtBox {
				padding: 32px;
			}
			h3 {
				font-size: $fzPC + 4;
				margin-bottom: 20px;
			}
			.guide {
				font-weight: $fwB;
				margin-bottom: 14px;
			}
		}
	}
	&_voice {
		background-color: $colorBg;
		padding-bottom: 48px;
		.sec_ttl {
			margin-bottom: 48px;
			.ttl_intro {
				display: inline-block;
				border-bottom: 3px solid $site-red;
				margin-bottom: 24px;
				padding-bottom: 8px;
				width: 730px;
				font-weight: $fwB;
			}
			span {
				font-size: $fzPC + 14;
			}
			img {
				margin: 0 3px -4px;
			}
			h2 {
				font-size: $fzPC + 28;
			}
		}
		li {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: flex-start;
			background-color: $white;
			padding: 32px;
			margin-bottom: 24px;
			font-size: $fzPC - 2px;
			.imgBox {
				width: 50%;
				max-width: 448px;
				margin-left: 28px;
				padding-top: 32%;
				position: relative;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.txtBox {
			width: 460px;
		}
		.car {
			font-size: $fzPC + 4;
			margin-bottom: 6px;			
		}
		h3 {
			font-size: $fzPC + 4;
			margin-bottom: 14px;
			line-height: 1.2;
		}
		.date {
			font-weight: $fwB;
			margin-bottom: 8px;
			span {
				margin-right: 10px;
			}
		}
	}
	&_flow {
		background-color: $colorBg;
		padding: 32px 0 78px;
		text-align: center;
		h2 {
			display: inline-block;
			font-size: $fzPC + 28;
			border-bottom: 3px solid $site-red;
			padding-bottom: 6px;
			margin-bottom: 40px;
		}
		ul {
			display: flex;
			margin-bottom: 40px;
		}
		li {
			width: 25%;
			margin-right: 30px;
			position: relative;
			font-size: $fzPC - 2;
			&:after {
				content: "";
				border-left: 22px solid #3A3A3A;
				border-top: 25px solid transparent;
				border-right: 22px solid transparent;
				border-bottom: 25px solid transparent;
				position: absolute;
				top: 17px;
				right: -34px;
			}
			&:last-child {
				margin: 0;
				&:after {
					content: none;
				}
			}
		}
		.icon {
			width: 80px;
			margin: 0 auto 42px;
		}
		strong {
			display: block;
			font-weight: $fwB;
			font-size: $fzPC + 10;
			line-height: 1.2;
			border-bottom: 3px solid $site-red;
			padding-bottom: 24px;
		}
	}
}